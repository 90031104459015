import { OriginatorEmailsConfigurationModel } from './email-configurations';

export enum ViewNames {
  repaymentSchedule = 'repaymentSchedule',
}

export interface BaseRate {
  id: string;
  encodedKey: string;
  branchEncodedKey: string;
  name: string;
  notes: string;
  type: string;
  creationDate: Date;
  lastModifiedDate: Date;
}

export interface TableColumnsConfiguration<T extends string = string> {
  name: T;
  active: boolean;
  editable: boolean;
}

export interface TableHeadersConfiguration<T extends string = string> {
  name: T;
  active: boolean;
  editable: boolean;
}

export enum OptionalFeatures {
  RISK_ANALYSIS = 'RISK_ANALYSIS',
  UNDERWRITING = 'UNDERWRITING',
  REPAYMENTS = 'REPAYMENTS',
  AUTOMATIC_APPLY_ACCRUED_INTEREST = 'AUTOMATIC_APPLY_ACCRUED_INTEREST',
  LOAN_ONBOARDING_CUSTOM_INFORMATION = 'LOAN_ONBOARDING_CUSTOM_INFORMATION',
  GROUP_LOANS = 'GROUP_LOANS',
  PAYMENTS_CSV = 'PAYMENTS_CSV',
}

export enum CustomInformationPageIds {
  LeaseEstateEUR = 'LeaseEstateEUR',
  LeaseEstateGBP = 'LeaseEstateGBP',
  KennekDemo = 'KennekDemo',
  ThinkProperty = 'ThinkProperty',
  FailedPage = 'FailedPage',
}

export interface ModulrData {
  customerId: string;
  customerName?: string;
  accountName?: string;
  sortCode?: string;
  branchEncodedKey?: string;
  beneficiaryIdentifier?: string;
  isPushPaymentEnabled?: boolean;
  directDebitAccountId?: string;
  active?: boolean;
  lendersBankAccountNumber?: number;
  lendersBankAccountHolder?: string;
  lendersBankAccountSortCode?: number;
}

export interface RiskAnalysisData {
  branchEncodedKey: string;
  codatApiKey?: string;
}

export interface BrandImageData {
  url: string;
  key: string;
  height: number;
  width: number;
}

export interface BrandData {
  name: string;
  logo: BrandImageData;
  favicon: BrandImageData;
  branchEncodedKey?: string;
  colors: {
    primary: ColorPalate;
    secondary: ColorPalate;
  };
  supportEmailAddress: string;
  bccEmailAddress: string;
  reportRecipientEmail: string;
  fromEmailAddress: string;
  emailConfigurations: OriginatorEmailsConfigurationModel;
  domainName: string;
}

export enum ProductFeatures {
  DOWNLOAD_REPAYMENT_SCHEDULE = 'DOWNLOAD_REPAYMENT_SCHEDULE',
  DOWNLOAD_REPAYMENT_STATEMENT = 'DOWNLOAD_REPAYMENT_STATEMENT',
  EDIT_REPAYMENT_COLUMNS = 'EDIT_REPAYMENT_COLUMNS',
  EDIT_REPAYMENT_SETTINGS = 'EDIT_REPAYMENT_SETTINGS',
  EDIT_REPAYMENT_SCHEDULE = 'EDIT_REPAYMENT_SCHEDULE',
  VIEW_TRANCHES = 'VIEW_TRANCHES',
  MANUAL_DISBURSEMENT = 'MANUAL_DISBURSEMENT',
  EDIT_LOAN = 'EDIT_LOAN',
  EDIT_INTEREST_RATE = 'EDIT_INTEREST_RATE',
  ADD_MANUAL_FEE = 'ADD_MANUAL_FEE',
}

export interface ColorPalate {
  C100: string;
  C200: string;
  C300: string;
  C400: string;
  C500: string;
  C600: string;
  C700: string;
  C800: string;
  C900: string;
}

export interface LegalDocumentsData {
  termsAndConditions: { url: string; key: string };
}

export interface LegalDocumentSettings {
  _id: string;
  activatedAt: string;
  fileName: string;
  isActive: boolean;
  isDefault: boolean;
  name: string;
  role: string;
  url: string;
}

export interface DirectDebitAnonymousSetupPageData {
  originatorBrand?: BrandData;
  mambuLoanId: string;
  mambuLoanEncodedKey: string;
  directDebitMandateSubmitted: boolean;
  loanName: string;
  borrowerEmail: string;
  borrowerName: string;
}

export type RequiredIfRule = {
  key: string;
  value: string | number | boolean | string[] | null;
  operator?: '>' | '<' | '==' | '!=';
};

export enum ProductComponent {
  LOAN_DETAILS = 'LOAN_DETAILS',
}

export enum ProductComponentItemDetail {
  //Common
  INTEREST = 'INTEREST',
  PRINCIPAL = 'PRINCIPAL',
  FEE = 'FEE',
  INTEREST_RATE = 'INTEREST_RATE',
  LTV = 'LTV',

  //LOAN_BALANCE_CARD
  TOTAL_OUTSTANDING = 'TOTAL_OUTSTANDING',
  PRINCIPAL_OUTSTANDING = 'PRINCIPAL_OUTSTANDING',
  INSTALMENTS_REMAINING = 'INSTALMENTS_REMAINING',
  LEFT_TO_PAY = 'LEFT_TO_PAY',

  //LOAN_NEXT_PAYMENT
  ACCRUED_INTEREST = 'ACCRUED_INTEREST',

  // LOAN_DETAILS_CARD
  LOAN_TYPE = 'LOAN_TYPE',
  LOAN_NAME = 'LOAN_NAME',
  LOAN_AMOUNT = 'LOAN_AMOUNT',
  BASE_INTEREST_RATE = 'BASE_INTEREST_RATE',
  SPREAD_INTEREST = 'SPREAD_INTEREST',
  INVESTED_AMOUNT = 'INVESTED_AMOUNT',
  INVESTOR_INTEREST = 'INVESTOR_INTEREST',
  INVESTOR_INTEREST_COMMISSION = 'INVESTOR_INTEREST_COMMISSION',
  INTEREST_TYPE = 'INTEREST_TYPE',
  REPAYMENT_AMOUNT = 'REPAYMENT_AMOUNT',
  REPAYMENT_INSTALMENTS = 'REPAYMENT_INSTALMENTS',
  REPAYMENT_FREQUENCY = 'REPAYMENT_FREQUENCY',
  ROLLUP_DURATION = 'ROLLUP_DURATION',
  INTEREST_CAPITALISATION_FREQUENCY = 'INTEREST_CAPITALISATION_FREQUENCY',
  PRINCIPAL_COLLECTION_FREQUENCY = 'PRINCIPAL_COLLECTION_FREQUENCY',
  PAYMENT_HOLIDAY = 'PAYMENT_HOLIDAY',
  MINIMUM_INTEREST_PERIOD = 'MINIMUM_INTEREST_PERIOD',
  INTEREST_GRACE = 'INTEREST_GRACE',
  INTEREST_PERIODS_RETAINED = 'INTEREST_PERIODS_RETAINED',
  GRACE_PERIOD = 'GRACE_PERIOD',
  GRACE_AMOUNT = 'GRACE_AMOUNT',
  PENALTY_RATE = 'PENALTY_RATE',
  EXIT_FEE = 'EXIT_FEE',
  MONTHLY_REPAYMENT_DAY = 'MONTHLY_REPAYMENT_DAY',
  DISBURSEMENT_DATE = 'DISBURSEMENT_DATE',
}

export enum ProductComponentItem {
  LOAN_BALANCE_CARD = 'LOAN_BALANCE_CARD',
  LOAN_FACILITIES_TABLE = 'LOAN_FACILITIES_TABLE',
  LOAN_REPORTS_TABLE = 'LOAN_REPORTS_TABLE',
  LOAN_DOCUMENTATION_TABLE = 'LOAN_DOCUMENTATION_TABLE',
  LOAN_TASKS_TABLE = 'LOAN_TASKS_TABLE',
  LOAN_INVESTORS_TABLE = 'LOAN_INVESTORS_TABLE',
  LOAN_HISTORY_TABLE = 'LOAN_HISTORY_TABLE',

  LOAN_TRANCHE_CARD = 'LOAN_TRANCHE_CARD',
  LOAN_NEXT_PAYMENT_CARD = 'LOAN_NEXT_PAYMENT_CARD',
  LOAN_WISERFUNDING_CARD = 'LOAN_WISERFUNDING_CARD',
  LOAN_DETAILS_CARD = 'LOAN_DETAILS_CARD',
  LOAN_COMPANY_INFO_CARD = 'LOAN_COMPANY_INFO_CARD',
  LOAN_CONTACT_CARD = 'LOAN_CONTACT_CARD',
}

export interface ProductComponentResponse {
  component: ProductComponent;
  componentConfiguration: {
    [K in ProductComponentItem]?: ProductComponentItemDetail[];
  };
}

export enum LabelType {
  BORROWER = 'BORROWER',
  LOAN = 'LOAN',
}

export interface LabelConfigurationPayload {
  branchEncodedKey: string;
  labelConfigs: LabelConfiguration[];
}

export interface LabelConfiguration {
  singular: string;
  plural: string;
  labelType: LabelType;
}

export interface LabelConfigurationListData extends LabelConfiguration {
  default: string;
}
