import { formatAmount, formatDate } from '@/utils/formatters';
import { LabelsConfig } from 'kennek/interfaces/labelsConfig';
import { Currencies } from 'kennek/interfaces/loans';

export const ABSOLUTE_MAX_CHARACTERS_MSG = (max: string | number) =>
  `Cannot be more than ${max} characters`;

export const EMPTY_TABLE_MESSAGES = {
  BORROWERS_DRAFTS: ({ borrowerLower }: LabelsConfig) =>
    `There are no pending ${borrowerLower} to review`,
  BORROWERS_APPROVED: ({ borrowerLower }: LabelsConfig) =>
    `There are no approved ${borrowerLower}`,
  BORROWERS_SET_FOR_DISBURSEMENT: ({ loansLower }: LabelsConfig) =>
    `There are no ${loansLower} scheduled for disbursement`,
  USERS_DRAFTS: 'There are no pending users to review',
  SETTINGS_LABEL_CONFIG: 'No labels available',
};

export const SETTINGS_MESSAGES = {
  PASSWORD_COMPOSITION:
    'Password must contain at least one:\n uppercase character (A-Z), lowercase\n character (a-z), digit (0-9), special\n character (~!@#$%^&*_-+=`|\\(){}[]:;\'"<>,.?/)',
  PASSWORD_MISMATCH: "Passwords doesn't match",
  CURRENT_PASSWORD_REQUIRED: 'Current password cannot be empty',
  CURRENT_PASSWORD_MAX_CHARACTERS: (max: number) =>
    `Cannot be more than ${max} characters`,
  NEW_PASSWORD_REQUIRED: 'New password cannot be empty',
  NEW_PASSWORD_MAX_CHARACTERS: (max: number) =>
    `Cannot be more than ${max} characters`,
  NEW_PASSWORD_MIN_CHARACTERS: (min: number) =>
    `Should be at least ${min} characters`,
  REPEAT_PASSWORD_REQUIRED: 'Repeat password cannot be empty',
  REPEAT_PASSWORD_MAX_CHARACTERS: (max: number) =>
    `Cannot be more than ${max} characters`,
  REQUIRED_PHONE_NUMBER: 'Phone number cannot be empty',
  PHONE_NUMBER_MAX_DIGITS: (max: number) => `Can't be more than ${max} digits`,
  PHONE_NUMBER_MIN_DIGITS: (min: number) => `Can't be less than ${min} digits`,
  REQUIRED_CODE: 'Verification code cannot be empty',
  CODE_MAX_DIGITS: (max: number) => `Can not contain more than ${max} digits`,
  CODE_MIN_DIGITS: (min: number) => `Must contain at least ${min} digits`,
  NUMBER_EQUALS_DIGITS: (length: number) => `Must be an ${length} digit number`,
  CODE_EQUALS_DIGITS: (length: number) => `Must be a ${length} digit number`,
  CODE_ONLY_DIGITS: 'Must be only digits',
  CODE_MIN_VALUE: (min: number) => `Must be greater than ${min}`,
  CODE_MAX_VALUE: (max: number) => `Must be less than ${max}`,
};

export const DIRECT_DEBIT_MESSAGES = {
  ACCOUNT_NAME_REQUIRED: 'Account name cannot be empty',
  ACCOUNT_NAME_MAX_CHARACTERS: (max: number) => `Cannot be more than ${max}`,
  ACCOUNT_NUMBER_REQUIRED: 'Account number cannot be empty',
  ACCOUNT_ALLOWED_CHARACTERS:
    'Account name cannot start with numbers or symbols',
  ACCOUNT_CODE_REQUIRED: 'Sort Code cannot be empty',
  ACCOUNT_NOT_FOUND_CONTENT: (supportEmailAddress = '') =>
    `Please contact us at ${
      supportEmailAddress ? supportEmailAddress : 'help@kennek.io'
    } in order to activate the Direct Debit`,
  ADDRESS_REQUIRED: 'Address cannot be empty',
  CITY_REQUIRED: 'City cannot be empty',
  POSTAL_CODE_REQUIRED: 'Cannot be empty',
  COUNTRY_REQUIRED: 'Country cannot be empty',
  COMPANY_TYPE_REQUIRED: 'Company type cannot be empty',
  COMPANY_NUMBER_REQUIRED: 'Company number cannot be empty',
  REQUIRED_SIC_CODE: 'Industry code cannot be empty',
  MAX_SIC_CODE: (max: number) => `Industry code cannot be more than ${max}`,
  MIN_SIC_CODE: (min: number) => `Industry code cannot be less than ${min}`,
  PHONE_NUMBER_REQUIRED: 'Phone number cannot be empty',
  PHONE_NUMBER_MAX: (max: number) =>
    `Phone number cannot have more than ${max} digits`,
  PHONE_NUMBER_MIN: (min: number) =>
    `Phone number cannot have less than ${min} digits`,
  BIRTH_DATE_REQUIRED: 'Birth date cannot be empty',
  EMAIL_REQUIRED: 'Email cannot be empty',
  OWNERSHIP_REQUIRED: 'Ownership cannot be empty',
  FIRST_NAME_REQUIRED: 'First name cannot be empty',
  LAST_NAME_REQUIRED: 'Last name cannot be empty',
  ACCOUNT_NUMBER_INVALID_FORMAT: 'Account  number must be a number',
  SORT_CODE_INVALID_FORMAT: 'Sort code must be a number',
  ORIGINATOR_NAME_MAX_CHARACTERS: (max: number) =>
    `Cannot be more than ${max} characters`,
  INVALID_POSTAL_CODE: 'Invalid format',
  TERMS_ACCEPTED: 'You need to accept the Terms and conditions to continue',
  SETUP_FAILED: 'Failed to activate Direct Debit',
  SETUP_FAILED_CONTENT: 'Please try again later',
  SETUP_SUCCESS: 'Direct debit activated',
  POSTAL_CODE_MAX_CHARACTERS: 'Postal code cannot have more than 8 characters',
};

export const RECONCILE_PAYMENT = {
  AMOUNT_REQUIRED: 'Payment amount cannot be empty',
  AMOUNT_MIN_AMOUNT: (amount: number, currency: Currencies) =>
    `Amount cannot be ${formatAmount(amount, true, currency)}`,
  AMOUNT_MAX_AMOUNT: (amount: number, currency: Currencies) =>
    `Amount cannot be greater than the total balance ${formatAmount(
      amount,
      true,
      currency
    )}`,
  AMOUNT_MAX_PAYOFF_AMOUNT: `To payoff full balance please use payoff feature`,
  DATE_REQUIRED: 'Date cannot be empty',
  PRE_PAYMENT_IN_DUE_DATE: ({
    dueAmount,
    currency,
    loanLower,
  }: {
    dueAmount: number;
    currency: Currencies;
    loanLower: string;
  }) => `Prepayments are not permitted for this ${loanLower}. 
    The maximum amount you can reconcile is the due amount (${formatAmount(
      dueAmount,
      true,
      currency
    )})`,
  DISBURSEMENT_DATE_VALIDATION: (
    date: string
  ) => `The transaction date cannot be after a pending tranche
    (${formatDate(date)})`,
  RECONCILE_ON_REQUIRED: 'Reconcile on is required',
};

export const PAYOFF = {
  CHANNEL_REQUIRED: 'You need to select a channel',
  TERMS_ACCEPTED: 'You need to accept the Terms and conditions to continue',
};

export const USER_MGMT_MESSAGES = {
  FIRST_NAME_REQUIRED: 'First name cannot be empty',
  FIRST_NAME_MAX_CHARACTERS: (max: number) =>
    `First name can not contain more than ${max} characters`,
  LAST_NAME_REQUIRED: 'Last name cannot be empty',
  LAST_NAME_MAX_CHARACTERS: (max: number) =>
    `Last name can not contain more than ${max} characters`,
  EMAIL_REQUIRED: 'Email cannot be empty',
  EMAIL_WRONG_FORMAT: 'Enter correct email',
  EMAIL_MAX_CHARACTERS: (max: number) =>
    `Email can not contain more than ${max} characters`,
  ROLE_REQUIRED: 'Role cannot be empty',
};

export const INVESTOR_MESSAGE = {
  COMPANY_NAME_REQUIRED: 'Company name cannot be empty',
  COMPANY_NUMBER_REQUIRED: 'Company number cannot be empty',
  COMPANY_NUMBER_DIGITS: (digits: string | number) =>
    `Company number should be ${digits} digits`,
  SIC_CODE_REQUIRED: 'Industry code cannot be empty',
  SIC_CODE_MAX_DIGITS: (max: number) => `Cannot be more than ${max} digits`,
  SIC_CODE_MIN_DIGITS: (min: number) => `Cannot be less than ${min} digits`,
  ADDRESS_REQUIRED: 'Address cannot be empty',
  CITY_REQUIRED: 'City cannot be empty',
  TOWN_REQUIRED: 'Town cannot be empty',
  POSTAL_CODE_REQUIRED: 'Cannot be empty',
  POSTAL_CODE_INVALID: 'Invalid format',
  COUNTRY_REQUIRED: 'Country cannot be empty',
  EMAIL_REQUIRED: 'Email cannot be empty',
  EMAIL_IN_USE: 'This email is already in use',
  EMAIL_INVALID: 'Invalid email format',
  CONTACT_FULL_NAME_REQUIRED: 'Contact full name cannot be empty',
  PHONE_REQUIRED: 'Phone number cannot be empty',
  PHONE_DIGITS: (digits: string | number) => `Phone should be ${digits} digits`,
  TRADING_NAME: 'Trading name cannot be empty',
  NAME_OF_CONTRACTING: 'Name of contracting cannot be empty',
  LENDER_OF_RECORD_NAME_REQUIRED: 'Name cannot be empty',
  JURISDICTION_REQUIRED: 'Jurisdiction cannot be empty',
  INVESTOR_REQUIRED: 'Investor cannot be empty',
  LENDER_REQUIRED: 'Lender cannot be empty',
  AMOUNT_REQUIRED: 'Amount cannot be empty',
  SAVE_INVESTOR_FAILED: 'Failed to save an investor',
  SAVE_LOAN_DO_NOT_SUPPORT: ({ loanUpper }) =>
    `This ${loanUpper} type doesn't support Investors`,
  FULL_NAME_INVALID: 'Full name must include first and last name',
  REQUIRED_INVESTOR_INTEREST:
    'Field cannot be empty. To skip investor rate, enter 0%',
  MIN_INVESTOR_INTEREST: 'Investor interest rate cannot be less than 0%',
  AMOUNT_TO_FUND_TYPE_REQUIRED: 'Select an Amount to fund',
};

export const FACILITY_MESSAGE = {
  FACILITY_AMOUNT_REQUIRED: 'Amount cannot be empty',
  FACILITY_NAME_REQUIRED: 'Please select a facility',
  AMOUNT_EXCEEDED: ({ loanUpper }: LabelsConfig) =>
    `Amount for all facilities cannot exceed ${loanUpper} amount`,
  AMOUNT_EXCEEDED_FOR_PAYMENT:
    'You cannot allocate more than the payment amount',
  AMOUNT_EXCEEDED_FOR_DISBURSEMENT_AMOUNT:
    'You cannot allocate more than the disbursement amount',
  AMOUNT_EXCEEDED_FOR_FACILITY: 'Amount cannot exceed facility amount',
  SAVE_SUCCESS: 'Added facility to the product',
  SAVE_FAILED: 'Failed to save a facility',
  NAME_ALREADY_EXIST: 'Facility already exists',
};

export const LOAN_STATEMENT_MESSAGES = {
  END_DATE_IN_THE_FUTURE: ({ loanUpper }: LabelsConfig) =>
    `${loanUpper} statement end date cannot be a future date`,
  END_DATE_BEFORE_START_DATE: ({ loanUpper }: LabelsConfig) =>
    `${loanUpper} statement end date cannot be earlier than start date`,
  END_DATE_REQUIRED: ({ loanUpper }: LabelsConfig) =>
    `${loanUpper} statement end date cannot be empty`,
};

export const EDIT_INVESTMENTS_MESSAGES = {
  EFFECTIVE_DATE_REQUIRED: `Effective date is required`,
  EFFECTIVE_DATE_CANNOT_BE_BEFORE_DISBURSEMENT: `Effective date cannot be before disbursement date`,
  EFFECTIVE_DATE_CANNOT_BE_AFTER_MATURITY: `Effective date cannot be after maturity date`,
};
