import { useEffect } from 'react';
import { useRouter, useSearchParams } from 'next/navigation';
import LoadingAnimationPlat from '@/components/common/LoadingAnimationPlat';
import { ROUTES } from '@/constants/routes';
import { useRole } from '@/hooks/useRole';
import useUserRole from '@/hooks/useUserRole';
import EmptyLayout from '@/layouts/empty';
import { useSelectUser } from '@/store/user/selectors';
import { getRouterQuery } from '@/utils/helpers';
import type { PageWithAuthType, PageWithLayoutType } from '@/types/pages';

const Page: PageWithLayoutType & PageWithAuthType = () => {
  const router = useRouter();
  const searchParams = useSearchParams();
  const loanId = searchParams.get('loanId');
  const user = useSelectUser();
  const { role: baseRole } = useRole();
  const role = useUserRole(baseRole, user, []);
  useEffect(() => {
    if (['BORROWER', 'BORROWER_UNDERWRITING'].includes(role)) {
      const path = getRouterQuery(
        ROUTES.DASHBOARD,
        loanId
          ? {
              loanId,
            }
          : {}
      );
      router.push(path);
    } else {
      router.push(ROUTES.SERVICING_LOANS);
    }
  }, [router]);

  return <LoadingAnimationPlat />;
};

Page.layout = EmptyLayout;
Page.requireAuth = true;

export default Page;
